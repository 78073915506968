/* eslint-disable react/jsx-props-no-spreading */
import * as React from "react";

const EyeHide: React.FC<React.SVGProps<SVGSVGElement>> = props => {
  return <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
            <path fillRule='evenodd' clipRule='evenodd' d='M1.34151 9.24742C1.75635 8.88443 2.3865 8.9257 2.75051 9.33912L2.7531 9.34201L2.77348 9.36424C2.79368 9.38597 2.82702 9.42117 2.87337 9.46794C2.96613 9.56154 3.11068 9.70113 3.30599 9.87157C3.69701 10.2128 4.28861 10.6753 5.07232 11.1397C6.6375 12.0672 8.96327 13 12 13C15.0368 13 17.3625 12.0672 18.9277 11.1397C19.7114 10.6753 20.303 10.2128 20.694 9.87157C20.8893 9.70113 21.0339 9.56154 21.1267 9.46794C21.173 9.42117 21.2064 9.38597 21.2265 9.36424L21.2469 9.34201L21.248 9.34086C21.2485 9.34028 21.249 9.33969 21.2495 9.33911C21.6135 8.9257 22.2437 8.88444 22.6585 9.24742C23.0742 9.6111 23.1163 10.2429 22.7526 10.6585L22 10C22.7526 10.6585 22.7523 10.6589 22.752 10.6592L22.7512 10.66L22.7496 10.6619L22.7452 10.6668L22.7326 10.6809C22.7225 10.6922 22.7088 10.7072 22.6917 10.7256C22.6574 10.7625 22.6092 10.8132 22.5472 10.8758C22.4232 11.001 22.2435 11.1739 22.0091 11.3784C21.5408 11.7872 20.8511 12.3247 19.9473 12.8603C18.1375 13.9328 15.4633 15 12 15C8.53675 15 5.86253 13.9328 4.05271 12.8603C3.14892 12.3247 2.45927 11.7872 1.99092 11.3784C1.75653 11.1739 1.57687 11.001 1.45283 10.8758C1.39079 10.8132 1.34258 10.7625 1.30833 10.7256C1.2912 10.7072 1.27756 10.6922 1.26741 10.6809L1.2548 10.6668L1.25046 10.6619L1.24878 10.66L1.24807 10.6592C1.24775 10.6589 1.24744 10.6585 2.00001 10L1.24744 10.6585C0.883755 10.2429 0.925872 9.6111 1.34151 9.24742Z' fill='currentColor' />
            <path fillRule='evenodd' clipRule='evenodd' d='M4.64734 10.8828C5.0683 11.2403 5.11974 11.8714 4.76224 12.2923L2.76224 14.6473C2.40473 15.0683 1.77366 15.1197 1.3527 14.7622C0.931739 14.4047 0.880297 13.7736 1.2378 13.3527L3.2378 10.9977C3.59531 10.5767 4.22638 10.5253 4.64734 10.8828Z' fill='currentColor' />
            <path fillRule='evenodd' clipRule='evenodd' d='M19.357 10.8855C19.7781 10.5282 20.4091 10.5799 20.7665 11.001L22.7625 13.353C23.1199 13.774 23.0682 14.4051 22.6471 14.7625C22.226 15.1198 21.595 15.0681 21.2376 14.647L19.2416 12.295C18.8842 11.874 18.9359 11.2429 19.357 10.8855Z' fill='currentColor' />
            <path fillRule='evenodd' clipRule='evenodd' d='M9.22235 12.7287C9.74773 12.899 10.0356 13.4629 9.86531 13.9883L8.95131 16.8083C8.78103 17.3337 8.21708 17.6216 7.6917 17.4513C7.16632 17.281 6.87846 16.7171 7.04874 16.1917L7.96274 13.3717C8.13303 12.8463 8.69697 12.5584 9.22235 12.7287Z' fill='currentColor' />
            <path fillRule='evenodd' clipRule='evenodd' d='M14.7474 12.7393C15.2713 12.5647 15.8376 12.8479 16.0122 13.3719L16.9492 16.1839C17.1238 16.7078 16.8406 17.2741 16.3166 17.4487C15.7927 17.6233 15.2264 17.3401 15.0518 16.8161L14.1148 14.0041C13.9402 13.4802 14.2234 12.9139 14.7474 12.7393Z' fill='currentColor' />
        </svg>;
};

export default EyeHide;